import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="economy--elasticsearch--infinite-scroll"
export default class extends Controller {
  static targets = ["sentinel"];

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  get observer() {
    if (!this._observer) {
      this._observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Dispatch a click event to the target element
            entry.target.click();
          }
        });
      });  
    }

    return this._observer;
  }

  sentinelTargetConnected(sentinel) {
    this.observer.observe(sentinel);
  }

  loadMore() {
    this.sentinelTarget.click();
  }
}