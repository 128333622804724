import App from "../d4bl_app";
import * as $ from "jquery";

const INPUT_SCREEN = "input";
const ERROR_SCREEN = "error";
const SUCCESS_SCREEN = "success";

class NewsletterSignupForm {
  constructor(root) {
    this.element = root;
    this.form = root.querySelector(".js-newsletter-form");
    this.emailInput = root.querySelector(".js-newsletter-email");
    this.screens = {};
    this.screens[INPUT_SCREEN] = root.querySelector(".js-newsletter-input-screen");
    this.screens[SUCCESS_SCREEN] = root.querySelector(".js-newsletter-success-screen");
    this.screens[ERROR_SCREEN] = root.querySelector(".js-newsletter-error-screen");
    this.errorMessage = root.querySelector(".js-newsletter-error");

    this.state = {
      screen: INPUT_SCREEN,
      errorMessage: null,
    };

    const apiEndpointBase = this.form.getAttribute("action");

    this.form.addEventListener("submit", (e) => {
      // Disallow typical form submission (which results in page redirect)
      e.preventDefault();

      $.ajax({
        type: "GET",
        url: apiEndpointBase,
        data: $(this.form).serialize(),
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        error: (res) => this.handleError(res),
        success: (res) => {
          if (res.result === "error") {
            this.handleError(res);
          } else {
            this.handleSuccess();
          }
        },
      });
    });

    root.addEventListener("click", (e) => {
      if (e.target.matches(".js-newsletter-retry")) {
        this.handleReset();
      }
    });
  }

  handleError(res) {
    console.log("Error signing up", res);
    const update = { screen: ERROR_SCREEN };

    if (res.msg) {
      if (res.msg.includes("already subscribed")) {
        update.errorMessage = "You’re already subscribed!";
      } else if (res.msg.includes("invalid")) {
        update.errorMessage = "This email is invalid. Please use another email address.";
      } else if (res.msg.includes("timeout")) {
        update.errorMessage = "Signup timed out.";
      } else {
        update.errorMessage = "Something went wrong...";
      }
    }

    this.update(update);
  }

  handleSuccess() {
    this.update({ screen: SUCCESS_SCREEN })
  }

  handleReset() {
    this.update({ screen: INPUT_SCREEN })
  }

  update(update) {
    // Merge state
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);

    if (update.hasOwnProperty("screen")) {
      this.renderScreenState(previousState);
    }

    if (update.hasOwnProperty("errorMessage")) {
      this.renderErrorMessageState(this.state.errorMessage);
    }
  }

  renderScreenState(previousState) {
    const previousScreen = this.screens[previousState.screen];
    const nextScreen = this.screens[this.state.screen];

    const endFadeInHandler = () => {
      nextScreen.style.setProperty("transition", "");
      nextScreen.setAttribute("aria-hidden", "false");
      nextScreen.removeEventListener("transitionend", endFadeInHandler);
    };
    nextScreen.addEventListener("transitionend", endFadeInHandler);

    const endFadeOutHandler = () => {
      previousScreen.style.setProperty("transition", "");
      previousScreen.setAttribute("aria-hidden", "true");
      previousScreen.style.setProperty("z-index", "-1");
      previousScreen.removeEventListener("transitionend", endFadeOutHandler);

      nextScreen.style.setProperty("transition", "opacity 300ms linear");
      nextScreen.style.setProperty("opacity", "1");
      nextScreen.style.setProperty("z-index", "1");
    }

    previousScreen.addEventListener("transitionend", endFadeOutHandler);
    previousScreen.style.setProperty("transition", "opacity 150ms linear");
    previousScreen.style.setProperty("opacity", "0");
  }

  renderErrorMessageState(message) {
    this.errorMessage.innerText = message;
  }
}

export const newsletterSignupForms = {
  current: [],
};

export const init = () => {
  App.addEventListener("turbo:load", () => {
    newsletterSignupForms.current = [
      ...document.querySelectorAll(".js-newsletter-signup"),
    ].map((instance) => new NewsletterSignupForm(instance));
  });
};
