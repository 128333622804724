// Stimulus
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Import Scripts
import "./utilities/polyfills";
import * as accordion from "./components/accordion";
import * as colorModeToggle from "./components/color_mode_toggle";
import * as crossfadeCarousel from "./components/crossfade_carousel";
import * as imageCarousels from "./components/image_carousel";
import * as newsletterSignupForm from "./components/newsletter_signup_form";

// TODO: figure out carousel showing in /edit view. Right now, it's busted before hitting save
// import { PostLoadObserver } from "./utilities/postLoadObserver";
// const postLoad = new PostLoadObserver("#magic-modules", { attributes: true, subtree: true }, reInitModules)

// BEGIN MAGIC_MODULE_IMPORT
import * as gridModule from './magic_modules/grid_module';
import * as videoModule from './magic_modules/video_module';
import * as stripeDonationModule from './magic_modules/stripe_donation_module';
// END MAGIC_MODULE_IMPORT

// Stimulus Init
window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

// Init Scripts
accordion.init();
colorModeToggle.init();
crossfadeCarousel.init();
imageCarousels.init();
newsletterSignupForm.init();

// BEGIN MAGIC_MODULE_INIT
gridModule.init();
videoModule.init();
stripeDonationModule.init();
// END MAGIC_MODULE_INIT
