import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { active: Boolean };

  change() {
    if (!this.activeValue) {
      this.activeValue = true;
      this.element.classList.add("active");
    } else {
      this.activeValue = false;
      this.element.classList.remove("active");
    }
  }
}
