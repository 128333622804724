/*
This is an element built with a shallow knowledge of Stimulus.
If transferring to another site, update as necessary.
*/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.update();
  }

  update() {
    const path = window.location.pathname;
    const elements = this.element.querySelectorAll("[data-url-value]");

    elements.forEach((element) => {
      if (path === element.dataset.urlValue) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }
}
