import { Controller } from "@hotwired/stimulus";
import { toggle } from "../utilities";

// Connects to data-controller="filter-list"
export default class extends Controller {
  static targets = ["item", "expanderButton", "wrapper", "radioLabel"];
  homepageWrapper = document.querySelector(".homepage-filter__wrapper"); // TODO: Make this more stimulus-y. Probably refactor this controller to be homepage only.
  
  connect() {
  };

  toggleChosen(e) {
    // Remove "chosen" class from all other labels.
    this.radioLabelTargets.map((t) => {
      if(t.classList.contains("chosen")){
        t.classList.remove("chosen");
      }
    })
    // Add "chosen" class to only the chosen label.
    toggle(e.currentTarget, "chosen");

    // Only make the filters clearable if they are not already.
    if (!this.homepageWrapper.classList.contains("clearable")) {
      toggle(this.homepageWrapper, "clearable");
    };
  };
}
