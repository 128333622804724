import app from "../d4bl_app";
import { GenericCarousel } from "./generic_carousel";

const INITIALIZED_CLASS_NAME = "crossfade-carousel--initialized";

/*
  NOTE:
    Unlike the Image Carousel component, this JS component does not have
    a dedicated view. Instead, we try to make this component work for many views.
    It is used as a sub-component in the Image Carousel component to render
    the slide captions.

    At its core, this component handles two tasks:

    1. Fade in/out slides on demand
    2. Make sure the carousel's height matches the tallest slide

    Additionally, this component inherits some accessibility concerns from GenericCarousel,
    such as announcing the current slide to screen readers. Worth noting that this feature
    can be turned off, which the Image Carousel does in order to not duplicate those
    screen reader announcements.
*/
export class CrossfadeCarousel extends GenericCarousel {
  constructor(element, options) {
    super(element, options);

    this.afterImagesLoad().then(() => {
      this.refreshLayout();
    });
  }

  get defaultState() {
    return Object.assign({}, super.defaultState, {
      carouselHeight: 0,
    });
  }

  afterImagesLoad() {
    const images = this.element.querySelectorAll("img");
    if (!images) {
      return Promise.resolve();
    }

    const promises = [...images].map((imageElement) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        // NOTE: not sure this would work if we implement srcsets?
        img.src = imageElement.getAttribute("src");
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.allSettled(promises);
  }

  refreshLayout() {
    this.update({
      carouselHeight: false,
    });
    const maxHeight = [...this.slides].reduce((runningMaxHeight, slide) => {
      const slideHeight = slide.offsetHeight;
      return slideHeight > runningMaxHeight ? slideHeight : runningMaxHeight;
    }, 0);
    this.update({
      carouselHeight: maxHeight,
    });
    this.trigger("layout");
  }

  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("index")) {
      this.renderCurrentSlide(this.state.index, previousState.index);
    }

    if (update.hasOwnProperty("carouselHeight")) {
      this.renderCarouselHeight(this.state.carouselHeight);
    }
  }

  renderCurrentSlide(currentIndex, previousIndex) {
    if (currentIndex === previousIndex) {
      return;
    }
    const currentSlide = this.slides[currentIndex];
    const previousSlide = this.slides[previousIndex];

    CrossfadeCarousel.fadeOutElement(previousSlide).then(() => {
      CrossfadeCarousel.fadeInElement(currentSlide);
    });
  }

  renderCarouselHeight(height) {
    if (height) {
      this.element.style.height = `${height}px`;
      this.element.classList.add(INITIALIZED_CLASS_NAME);
    } else {
      this.element.style.removeProperty("height");
      this.element.classList.remove(INITIALIZED_CLASS_NAME);
    }
  }

  /* Public methods */
  resize() {
    this.refreshLayout();
  }

  /* Utilities */
  static fadeInElement(element) {
    return new Promise((resolve) => {
      const handler = () => {
        element.removeEventListener("transitionend", handler);
        resolve();
      };
      element.addEventListener("transitionend", handler);
      element.style.setProperty("visibility", "visible");
      element.style.setProperty("transition", "opacity 600ms ease-in");
      element.style.setProperty("opacity", 1);
    });
  }

  static fadeOutElement(element) {
    return new Promise((resolve) => {
      const handler = () => {
        element.removeEventListener("transitionend", handler);
        element.style.setProperty("visibility", "hidden");
        resolve();
      };
      element.addEventListener("transitionend", handler);
      element.style.setProperty("visibility", "visible");
      element.style.setProperty("transition", "opacity 300ms ease-in");
      element.style.setProperty("opacity", 0);
    });
  }
}

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    // NOTE: the Image Carousel component initializes its own Crossfade Carousel.
    // This initialization is inteneded for standalone instances of the Crossfade Carousel.
    [...e.target.querySelectorAll(".js-crossfade-carousel")].map(
      (instance) => new CrossfadeCarousel(instance)
    );
  });
};
