export const isEconomyEditMode = () =>
  document.documentElement.classList.contains("edit") ||
  document.documentElement.classList.contains("record");

// TODO: we dont support IE11, so maybe we don't need this?
export const forEachNodelist = (nodeList, callback) => {
  Array.prototype.forEach.call(nodeList, callback);
};

// TODO: we dont support IE11, so maybe we don't need this?
export const mapNodelist = (nodeList, callback) => {
  Array.prototype.map.call(nodeList, callback);
};

export const toggle = (element, class_name) => {
  element.classList.contains(class_name) ?
  element.classList.remove(class_name) :
  element.classList.add(class_name);
}

export const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

export const loopAround = (val, min, max) => {
  if (val > max) {
    return min;
  }
  if (val < min) {
    return max;
  }
  return val;
};

export const isSplitContent = (element) => {
  return Boolean(element.closest(".split-content"));
};

export const fetchPageMarkup = (url) => {
  return fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => res.text())
    .catch((err) => {
      console.error("Could not load the requested page.", err); // eslint-disable-line no-console
    });
};

export const fetchJson = (url) => {
  return fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error("Could not load the requested page.", err); // eslint-disable-line no-console
    });
};

export const cookiePrefix = "_economy_samfox_";

// Copied from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript#24103596
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${cookiePrefix}${name}=${value}${expires}; SameSite=Lax; path=/`;
};

// Copied from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript#24103596
export const getCookie = (name) => {
  const nameEQ = `${cookiePrefix}${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const editSearchParams = (url, cb) => {
  // The URL interface treats relative URLs as invalid unless we pass in a base.
  const formatted =
    url.startsWith("/") || url.startsWith("?")
      ? new URL(url, window.location.origin)
      : new URL(url);
  const params = new URLSearchParams(formatted.search);
  cb(params, url);
  formatted.search = params.toString();
  return formatted.href;
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthName = (date) => {
  return monthNames[date.getMonth()];
};

// Fisher-Yates shuffle from Mike Bostock. See this article for documentation:
// https://bost.ocks.org/mike/shuffle/
export const shuffleArray = (array) => {
  const shuffled = array;
  let currentIndex = shuffled.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex],
      shuffled[currentIndex],
    ];
  }

  return shuffled;
};
