import app from "../d4bl_app";
import { GenericFlickityCarousel } from "./generic_flickity_carousel";
import { CrossfadeCarousel } from "./crossfade_carousel";
import Flickity from "flickity";

/*
  This javascript handles all basic image carousels across the site.
  It corresponds to the `image_carousel` partial, which actually contains
  3 carousels. The partial renders separate desktop and mobile views.
  The responsive design is not possible from one set of markup alone.
  Additionally, the desktop view contains two Flickity instances to
  reduce the amount of motion in the text. This javascript extends
  the GenericCarousel to accomodate those three carousel.

  Refer to GenericCarousel for methods you can use, including examples
  about how you can get a specific instance.
*/

export class ImageCarousel extends GenericFlickityCarousel {
  beforeInit(options) {
    this.config = Object.assign(this.defaultConfig, options);
    const sliders = this.element.querySelectorAll(
      ".js-image-carousel-flickity"
    );
    this.subcarousels = [...sliders].map((s) => {
      const { config } = this;
      if (s.getAttribute("data-draggable") === "true") {
        config.draggable = true;
      }
      if (s.hasAttribute("data-lazyload")) {
        config.lazyLoad = s.getAttribute("data-lazyLoad");
      }
      return new Flickity(s, config);
    });

    const textCarousel = this.element.querySelector(
      ".js-image-carousel-text-slides"
    );
    if (textCarousel) {
      this.subcarousels.push(
        new CrossfadeCarousel(textCarousel, {
          wrapAround: this.config.wrapAround,
          accessibility: false,
        })
      );
    }
  }

  get defaultState() {
    try {
      return {
      index: 0,
      maxIndex: this.subcarousels[0].slides.length - 1,
      canGoNext: true,
      canGoPrevious: false,
      };
    } catch(e) {
      console.log(e);
    }
  }

  /* Public methods */
  actOnAll(cb) {
    this.subcarousels.forEach(cb);
  }

  select(index) {
    this.actOnAll((f) => f.select(index));
    this.updateIndex();
    this.updateControls();
  }

  next() {
    this.actOnAll((f) => f.next());
    this.updateIndex();
    this.updateControls();
  }

  previous() {
    this.actOnAll((f) => f.previous());
    this.updateIndex();
    this.updateControls();
  }

  destroy() {
    this.actOnAll((f) => f.destroy());
    ImageCarousel.current = ImageCarousel.current.filter((c) => c !== this);
  }

  refreshLayout() {
    this.actOnAll((f) => f.resize());
  }

  /* State management functions */
  updateIndex() {
    const index = this.subcarousels[0].selectedIndex;
    this.update({ index });
  }
}

export const init = () => {
  app.addEventListener("pageLoad", (e) => {
    [...e.target.querySelectorAll(".js-image-carousel")].map(
      (instance) => new ImageCarousel(instance)
    );
  });
};
