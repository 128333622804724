import App from "../d4bl_app";

class ColorModeToggle {
  constructor(root) {
    this.element = root;
    this.targetElement = document.querySelector("body");

    if (root) {
      root.addEventListener("click", () => {
        this.toggle();
      });
    }
  }

  toggle() {
    if (this.targetElement.classList.contains("dark-mode")) {
      this.targetElement.classList.remove("dark-mode");
      this.targetElement.classList.add("light-mode");
    } else {
      this.targetElement.classList.remove("light-mode");
      this.targetElement.classList.add("dark-mode");
    }
  }
}

export const init = () => {
  App.addEventListener("turbo:load", () => {
    const instance = document.querySelector(".js-color-mode-toggle");
    return new ColorModeToggle(instance);
  });
};
