import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link"
export default class extends Controller {
  connect() {
    [...document.querySelectorAll('a')].map((anchor) => {
      if ( this.isExternalLink(anchor) ) {
        anchor.setAttribute('target', '_blank');
        anchor.classList.add('external-link');
      }
    });
  }

  isExternalLink(element) {
    const documentHost = document.location.href.split('/')[2];
    const href = element.getAttribute('href');
    const linkHost = href?.split('/')[2];
    return linkHost != documentHost && !this.internalLinkMatches(href).includes(true) && !this.isSimpleRoute(href);
  };

  internalLinkMatches(href) {
    const internalLinkExceptions = ['mailto:', 'tel:', 'sms:', 'javascript:'];
    return internalLinkExceptions.map((ex) => {
      if ( href ) {
        return href.indexOf(ex) != -1;
      } else {
        return true;
      }
    });
  };

  isSimpleRoute(href) {
    return ["/","#"].includes(href[0]);
  };
}
