import Flickity from "flickity";
import { GenericCarousel } from "./generic_carousel";

export class GenericFlickityCarousel extends GenericCarousel {
  constructor(element, options, elements) {
    super(element, options, elements);

    GenericFlickityCarousel.current.push(this);
  }

  get defaultState() {
    const parentDefaultState = super.defaultState;
    return Object.assign({}, parentDefaultState, {
      maxIndex: this.flickity.slides.length - 1,
    });
  }

  beforeInit() {
    this.flickity = new Flickity(this.slideWrapper, this.config);
  }

  /* Public methods */
  select(index) {
    super.select(index);
    this.flickity.select(index);
  }

  next() {
    super.next();
    this.flickity.next();
  }

  previous() {
    super.previous();
    this.flickity.previous();
  }

  destroy() {
    super.destroy();
    GenericFlickityCarousel.current = GenericFlickityCarousel.current.filter(
      (c) => c !== this
    );
    this.flickity.destroy();
  }

  refreshLayout() {
    this.flickity.resize();
    this.trigger("layout");
  }

  /* Public helper methods */
  static getInstance(element) {
    return GenericCarousel.current.find((i) => {
      return i.element === element || i.flickity === element;
    });
  }

  /* Helper functions */
  /* eslint-disable class-methods-use-this */
  get defaultConfig() {
    return {
      wrapAround: true,
      draggable: false,
      prevNextButtons: false,
      pageDots: false
    };
  }
}

GenericFlickityCarousel.current = [];
