import { Controller } from "@hotwired/stimulus";
import { toggle } from "../utilities";

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["showHide", "wrapper", "clear", "radioLabel", "form", "users"]

  toggleExpanded() {
    toggle(this.wrapperTarget, "expanded");
  };

  clearStyles(e) {
    this.radioLabelTargets.map( (t) => {
      if(t.classList.contains("chosen")){
        t.classList.remove("chosen");
      }
    })
    this.formTarget.reset();
  }

  formSubmit(e) {
    if(e.currentTarget.name == "theme" || e.currentTarget.name == "type"){
      this.usersTargets.map((t)=>{ t.checked = false })
    };
    e.currentTarget.form.requestSubmit();
  }

  connect() {
  }
}
