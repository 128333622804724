/*
This is an element built with a shallow knowledge of Stimulus.
If transferring to another site, update as necessary.
*/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.topLevelItems = ["/", "conferences", "about", "dispatch", "get-involved", "decompress"];
    this.update();
  }

  update() {
    let pathParts = window.location.pathname.split("/").filter((p) => p);
    if (pathParts.length === 0) {
      pathParts = ["/"];
    }
    const pathRoot = pathParts[0];

    if (this.topLevelItems.includes(pathRoot)) {
      const elements = this.element.querySelectorAll("[data-url-value]");
      elements.forEach((element) => {
        if (pathRoot === element.dataset.urlValue) {
          element.classList.add("active");
        } else {
          element.classList.remove("active");
        }
      });
    }
  }
}
