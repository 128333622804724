import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"];
  static values = { active: String };

  connect() {
    if (!this.activeValue) {
      this.activeValue = this.tabTarget.dataset.tabKey;
    }

    // TODO Refactor this to be more Stimulus-y
    if(document.getElementById("homepage-projects-frame")) {
      const projectFrame = document.getElementById("homepage-projects-frame");
      projectFrame.classList.contains("hidden") ?
      projectFrame.classList.remove("hidden") :
      projectFrame.classList.add("hidden");
    }
  }

  change(event) {
    this.activeValue = event.target.dataset.tabKey;
  }

  activeValueChanged(key) {
    const elements = this.element.querySelectorAll("[data-tab-key]");

    elements.forEach((element) => {
      if (key === element.dataset.tabKey) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }
}
