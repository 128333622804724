import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video"
export default class extends Controller {
  static values = {
    url: String
  };

  static targets = [ "poster", "playPause", "frameContainer", "frame" ];
  
  posterToVideo(){
    this.posterTarget.classList.add("fading");
    const iframe = this.urlToFrame();
    this.frameContainerTarget.appendChild(iframe);
    setTimeout(() => {
      this.frameContainerTarget.style.display = 'block';
      this.setIframeHeight();
      this.posterTarget.remove();
    }, 400);
  };

  urlToFrame(){
    try {
      if (this.urlValue.includes("vimeo")) {
        return this.generateIframe(`//player.vimeo.com/video/${this.getVideoId('https://vimeo.com/')}?autoplay=1&badge=0&byline=0&color=fff&portrait=0&title=9&muted=1`);

      } else if (this.urlValue.includes("youtu.be")) {
        return this.generateIframe(`https://www.youtube-nocookie.com/embed/${this.getVideoId('https://youtu.be/')}?rel=0&showinfo=0&autoplay=1&mute=1`);
        
      } else if (this.urlValue.includes("youtube")) {
        return this.generateIframe(`https://www.youtube-nocookie.com/embed/${this.getVideoId('https://www.youtube.com/watch?v=')}?rel=0&showinfo=0&autoplay=1&mute=1`);

      } else {
        throw "Unable to parse URL"
      };
    } catch (error) {
      console.warn(error)
    };
  };
  
  getVideoId(videoHost) {
    return this.urlValue.substr(this.urlValue.indexOf(videoHost) + videoHost.length);
  };

  generateIframe(source) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', source);
    iframe.setAttribute('class', 'video__iframe');
    iframe.setAttribute('width', '1072');
    iframe.setAttribute('height', '700');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('webkitallowfullscreen', '');
    iframe.setAttribute('mozallowfullscreen', '');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('data-video-target', 'frame');
    return iframe;
  };

  setIframeHeight(){
    const width = this.frameTarget.offsetWidth;
    const height = this.frameTarget.offsetHeight;
    
    const ratio = width / height;
    const parentWidth = this.frameContainerTarget.offsetWidth;
    const newHeight = parentWidth / ratio;

    this.frameTarget.style.width = parentWidth;
    this.frameTarget.style.height = newHeight;
  };
}
