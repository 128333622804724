import { Controller } from "@hotwired/stimulus";
import { toggle } from "../utilities";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["header", "submit", "form", "footer", "base", "main", "homeSearch"]

  toggleForm() {
    if (!this.baseTarget.classList.contains("active")) {
      if(this.mainTarget.querySelector(".home-search")) {
        window.scrollTo({top: 645, behavior: "smooth"});
        this.homeSearchTarget.querySelector("#query").focus();
      } else {
        toggle(this.formTarget, "active");
        this.formTarget.querySelector("#query").focus();
      }
    }
  }

  toggleHeader() {
    if (!this.baseTarget.classList.contains("active"))
      toggle(this.headerTarget, "active");
  }

  toggleResults() {
    toggle(this.resultsTarget, "active")
  }

  toggleFooter() {
    if (!this.baseTarget.classList.contains("active"))
      toggle(this.footerTarget, "active");
  }

  toggleMain() {
    if (!this.baseTarget.classList.contains("active"))
      toggle(this.mainTarget, "active");
  }

  toggleBase() {
    setTimeout(()=> {
      if (!this.baseTarget.classList.contains("active"))
        this.baseTarget.classList.add("active");
    }, 500);
  }

  closeFromMain() {
    // If search is active, and user isn't clicking in the search bar, close everything
    if (this.mainTarget.classList.contains("active")) {
      toggle(this.formTarget, "active");
      toggle(this.mainTarget, "active");
    }
  }
}
