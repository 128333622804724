import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js"

// Connects to data-controller="stripe-donation"
export default class extends Controller {
  static targets = ["wrapper", "button"];
  static values = {
    apiKey: String
  }
  domain = location.href.replace(/[^/]*$/, "");
  stripe = null;

  async connect() {
    this.stripe = await loadStripe(this.apiKeyValue);
  }

  async checkout({params: {checkoutMode, priceId}}) {
    try {
      await this.stripe.redirectToCheckout({
          mode: checkoutMode,
          lineItems: [{ price: priceId, quantity: 1 }],
          successUrl: this.domain + "thank-you?session_id={CHECKOUT_SESSION_ID}",
          cancelUrl: this.domain + "donate?session_id={CHECKOUT_SESSION_ID}"
      });
    } catch (error) {
      console.log(error);
    }
  }
}
